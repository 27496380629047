/* You can add global styles to this file, and also import other style files */

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$areas-primary: mat.define-palette(mat.$teal-palette);
$areas-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
//$default-palette: (
//  50: #e6eef8,
//  100: #c1d5ed,
//  200: #98b9e2,
//  300: #6e9cd6,
//  400: #4f87cd,
//  500: #3072c4,
//  600: #2b6abe,
//  700: #245fb6,
//  800: #1e55af,
//  900: #1342a2,
//  A100: #d4e0ff,
//  A200: #a1bcff,
//  A400: #6e98ff,
//  A700: #5486ff,
//  contrast: (
//    50: $dark-primary-text,
//    100: $dark-primary-text,
//    200: $dark-primary-text,
//    300: $light-primary-text,
//    400: $light-primary-text,
//    500: $light-primary-text,
//    600: $light-primary-text,
//    700: $light-primary-text,
//    800: $light-primary-text,
//    900: $light-primary-text,
//    A100: $dark-primary-text,
//    A200: $light-primary-text,
//    A400: $light-primary-text,
//    A700: $light-primary-text,
//  )
//);
// The warn palette is optional (defaults to red).
$areas-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$areas-theme: mat.define-light-theme(
  (
    color: (
      primary: $areas-primary,
      accent: $areas-accent,
      warn: $areas-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.

@include mat.all-component-themes($areas-theme);

@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700&family=Poppins:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined');
@import 'variables';

@import 'global';

html,
body {
  height: 100%;
}

body {
  box-sizing: border-box;
  font-family: Manrope, sans-serif !important;
  overflow: hidden;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.w-40 {
  width: 40%;
}

.w-33 {
  width: 33%;
}

.h-100 {
  height: 100%;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.wrap {
  flex-wrap: wrap;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.justify-right {
  justify-content: flex-end;
}

.align-items-center {
  align-items: center;
}

.align-items-start {
  align-items: flex-start;
}

.mat-grid-list {
  //width: 1664px !important;
}

.mat-grid-tile-content {
  //margin-top: 30px !important;
}

input[type='checkbox'] {
  width: 22px;
  height: 22px;
  margin: 0;
  border: 1px solid $grey;
}
app-extended-filters-list {
  .checkbox {
    label::before,
    label::after {
      top: 0px !important;
      margin-top: 0px !important;
    }
  }
}

app-user-profile-field {
  .mdc-checkbox__background {
    width: 20px;
    height: 20px;
    border: 1px solid #97a8a6 !important;
    border-radius: 50%;
  }
  .mdc-checkbox__checkmark {
    width: 15px;
    top: 2px;
    left: 2px;
  }
}

app-user-profile-form .checkbox-wrapper .form-group {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 6px;
  label {
    margin-bottom: 0 !important;
  }
}

@media screen and (max-width: 768px) {
  .cdk-overlay-pane:has(app-extended-filters-list) {
    max-width: 100vw !important;
    max-height: 100vh !important;
    min-height: 100vh !important;
    .wrapper {
      max-height: 100%;
    }
    .modal-background {
      padding: 0;
    }
  }
}
